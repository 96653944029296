// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.custom-dropdown-item:hover {
  background-color: lightblue !important; 
}

.review-card {
  background-color: blue; 
  border: 100px solid red; 
  border-radius: 80px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 40px red;
  transition: transform 0.3s ease-in-out;
}

.review-card:hover {
  transform: translateY(-5px);
}

.rating-stars {
  color: blue;
  border: blue; 
  margin-bottom: 10px;
}

.star-icon {
  font-size: 1.2rem;
  margin-right: 2px;
}

.card-img-top {
  width: 100%;
  height: 600px;
  
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px; 
}

.about {
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
  
}

.storage{
  height: 300px; /* Adjust the height as needed */
  object-fit: cover;
  width: 100%;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,sCAAsC;AACxC;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,0BAA0B;EAC1B,sCAAsC;AACxC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;;AAEf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE;;AAEF;;AAEA;EACE,aAAa,EAAE,gCAAgC;EAC/C,iBAAiB;EACjB,WAAW;AACb","sourcesContent":["\n.custom-dropdown-item:hover {\n  background-color: lightblue !important; \n}\n\n.review-card {\n  background-color: blue; \n  border: 100px solid red; \n  border-radius: 80px;\n  padding: 20px;\n  margin-bottom: 20px;\n  box-shadow: 0 2px 40px red;\n  transition: transform 0.3s ease-in-out;\n}\n\n.review-card:hover {\n  transform: translateY(-5px);\n}\n\n.rating-stars {\n  color: blue;\n  border: blue; \n  margin-bottom: 10px;\n}\n\n.star-icon {\n  font-size: 1.2rem;\n  margin-right: 2px;\n}\n\n.card-img-top {\n  width: 100%;\n  height: 600px;\n  \n}\n\n.card-body {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 200px; \n}\n\n.about {\n  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif\n  \n}\n\n.storage{\n  height: 300px; /* Adjust the height as needed */\n  object-fit: cover;\n  width: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
